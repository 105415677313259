* {
  padding: 0;
  margin: 0;
}

body,
html {
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  height: 100%;
}

html {
  overflow: hidden;
}

body {
  overflow: auto;
}

#root {
  width: 100%;
  height: 100%;
  display: block;
}

#touch,
#win {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#win {
  color: #fff;
  display: none;
  opacity: 0;
  transition: opacity 0.6s ease-in-out 0.5s;
}

#win.active {
  opacity: 1;
}

#win .button {
  opacity: 0;
  transition: opacity 0.6s ease-in-out 1s;
}

#win.active .button {
  opacity: 0.9;
}

.screen-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 15px;
  text-align: center;
}

.button {
  display: inline-block;
  position: relative;
  border: 0;
  font-size: 18px;
  cursor: pointer;
  padding: 10px 15px;
  background-color: #fff;
  color: #345069;
  border-radius: 3px;
  user-select: none;
}
